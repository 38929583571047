
function renderFrontIDCamera(o, s, os, oe) {
    var container = document.getElementById("container-sdk");
    $(container).show();
    o.renderCamera('front', container, { 
           onSuccess: r => { 
               $(container).hide();
               os.handleLogFront(r);
           }, 
           onError: error => {
               $(container).hide();
               oe.handleErrorFront(error);
           }, 
           token: s });
}

function renderBackIDCamera(o,s, os, oe) {
    var container = document.getElementById("container-sdk");
    $(container).show();
    o.renderCamera('back', container, { 
           onSuccess: r => { 
               $(container).hide();
               os.handleLogBack(r);
           }, 
           onError: error => {
               $(container).hide();
               oe.handleErrorBack(error);
           }, 
           token: s });
}

function renderSelfie(o,s, os, oe) {
    var container = document.getElementById("container-sdk");
    $(container).show();
    o.renderCamera('selfie', container, { 
               onSuccess: r => { 
                   $(container).hide();
                   os.handleLogSelfie(r);
               }, 
               onError: error => {
                   $(container).hide();
                   oe.handleErrorSelfie(error);
               }, 
               numberOfTries: 3,
               token: s });
}

function renderSelfieAnimation(o,s, os, oe, ln, ue) {
    var container = document.getElementById("container-sdk");
    $(container).show();
    o.renderFaceMatch(container, { 
               onSuccess: r => { 
                   $(container).hide();
                   os.handleLogSelfieAnimation(r);
               }, 
               onError: error => {
                   $(container).hide();
                   oe.handleErrorSelfieAnimation(error);
               }, 
               liveness: ln,
               userExists: ue,
               token: s });
}

function renderAddressDoc(o,s, os, oe) {
    var container = document.getElementById("container-sdk");
    $(container).show();
    o.renderCamera('document', container, { 
           onSuccess: r => { 
               $(container).hide();
               os.handleLogDocument(r);
           }, 
           onError: error => {
               $(container).hide();
               oe.handleErrorDocument(error);
           }, 
           numberOfTries: 3,
           permissionBackgroundColor: "#696969",
           sendBase64: false,
           nativeCamera: false,
           token: s });

}

function renderSignatureDoc(o,s, os, oe) {
    var container = document.getElementById("container-sdk");
    $(container).show();
    o.renderSignature(container, { 
           onSuccess: r => { 
               $(container).hide();
               os.handleLogSignature(r);
           }, 
           onError: error => {
               $(container).hide();
               oe.handleErrorSignature(error);
           }, 
           token: s.token });
}

function renderVideoSelfie(o,s, os, oe) {
    var container = document.getElementById("container-sdk");
    $(container).show();
    o.renderVideoSelfie(container, 
            {
                token: s,
                modules: ['selfie'], // you can add 'poa' and 'questions' 'selfie', 'front', 'back', 'poa', 'questions', 
                speechToTextCheck: true, // this is the check for the speech
              },
              {
                onSuccess: r => { 
                    $(container).hide();
                    os.handleLogVideoSelfie(r);
                }, 
                onError: error => {
                    $(container).hide();
                    oe.handleErrorVideoSelfie(error);
                },
                numberOfTries: 3,
              });
}